.parley {
    text-align: center;
    font-family: 'Space Mono';
}

.parley-container {
    display: flex;
    justify-content: center;
}

.selected-winners {
  display: inline-block;
  text-align: center;
  list-style-type: none;
}

.games-table {
    width: 500px;
    border-collapse: collapse;
}
  
  .games-table td {
    padding: 5px;
    text-align: center;
    vertical-align: middle;
  }
  
  .games-table .separator {
    font-weight: bold;
  }
  
  .games-table .left-column {
    text-align: left;
  }
  
  .games-table .right-column {
    text-align: right;
  }

  .team-logo {
    vertical-align: middle;
  }

.games-table input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #333;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    position: relative; /* Add positioning context for the pseudo-element */
    vertical-align: middle;
  }
  
  /* Style the checked state of checkboxes */
  .games-table input[type="checkbox"]:checked {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
  }
  
  /* Add a checkmark symbol when the checkbox is checked */
  .games-table input[type="checkbox"]:checked::before {
    content: "\2713"; /* Unicode checkmark symbol */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px; /* Adjust the size of the checkmark */
  }