@font-face {
  font-family: 'Space Mono';
  src: url('./fonts/Space_Mono/SpaceMono-Regular.ttf') format('truetype');
  /* Add additional font formats if needed */
}

@font-face {
  font-family: 'Prompt';
  src: url('./fonts/Prompt/Prompt-Black.ttf') format('truetype');
  /* Add additional font formats if needed */
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2F1f0;
  max-width: 100%;
}

.active {
  background-color: #FFAF5D; 
  border-radius: 5px; 
} 

.active, .inactive {
  font-family: 'Prompt';
  color: #6B5045;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  padding: 5px 10px; 
}

ul {
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

li {
  position: relative; /* Needed for the dropdown */
}

.navbar-logo {
  max-width: 150px;
  margin: 20px;
  justify-content: center;
}

/* Dropdown menu */
li ul.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #f2f1f0;
  padding: 10px;
  list-style-type: none;
  border-radius: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

li:hover ul.dropdown {
  display: block;
}

li ul.dropdown li {
  display: block;
  padding: 5px 10px;
}

li ul.dropdown li.active {
  background-color: #FFAF5D; 
  border-radius: 5px;
}

li ul.dropdown li.inactive {
  background-color: transparent;
}

/* Selector  */
.week-selector, .month-selector {
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: 'Prompt';
  color: #6B5045;
  margin-top: 10px;
}

/* Grid */
.posts-container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
}

.post-box {
  width: 80%;
  text-align: left;
  border-radius: 10px;
  border: 2px solid grey;
  font-family: 'Space Mono';
  padding: 5%;
  margin-bottom: 5%;
}

.games-container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
}

.game-box {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 150px;
  padding: 0;
  margin: 25px 18px;
  font-size: .75rem;
  font-family: 'Space Mono';
  border-radius: 10px;
  border: 2px solid grey;
}

.game-table {
  padding: 1rem;
  text-align: left;
  font-family: 'Space Mono';
  font-size: 14.5px;
  height: 100%;
  width: 100%;
}

th {
  padding: 1%;
  background-color: #FFAF5D;
  border-radius: 10px;
  text-align: center;
  font-size: 95%;
}

td {
  text-align: center;
  padding: 0;
}

.team-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.team-logo {
  padding-right: 10px;
  width: 35px;
  height: 35px;
}

.intros {
  font-family: 'Space Mono';
  text-align: center;
}

.score {
  font-family: 'Prompt';
  color: #6B5035;
}

a:hover,
a:visited,
a:active {
    color: #0077cc; /* Set the same color for hover, visited, and active states */
}

pre {
  background: lightgray;
  border-radius: 10px;
  padding: 5%;
  overflow: scroll;
}

p {
  overflow: scroll;
}

.game-date {
  font-family: 'Space Mono';
  text-align: center;
}

.mlb-schedule {
  padding: 10px;
}

.month-display {
  display: flex; /* Makes the container a flex container */
  flex-direction: column; /* Stacks the flex items vertically */
  align-items: center; /* Centers children vertically in the container */
}