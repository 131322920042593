/* @font-face {
    font-family: 'Space Mono';
    src: url('../fonts/Space_Mono/SpaceMono-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Prompt';
    src: url('../fonts/Space_Mono/Prompt-Black.ttf') format('truetype');
  } */

.games-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-evenly;
}

.game-date {
    font-family: 'Space Mono';
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 5px; /* Adjust spacing as needed */
}

.game-box {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 150px;
    padding: 0;
    margin: 25px 18px;
    font-size: .75rem;
    font-family: 'Space Mono';
    border-radius: 10px;
    border: 2px solid grey;
  }

  .game-table {
    padding: 1rem;
    text-align: left;
    font-family: 'Space Mono';
    font-size: 14.5px;
    height: 100%;
    width: 100%;
  }

.game-table th {
    background-color: #f4a261; /* Adjust color to fit your theme */
    border-radius: 5px;
}

.month-selector {
}